import { useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row,Col,Image,ListGroup,Card,Button,Form } from "react-bootstrap";
import Rating from "../components/Rating";
import { useDispatch,useSelector } from "react-redux";
import { useGetProductDetailsQuery,useCreateReviewMutation } from "../slices/productsApiSlice";
import {toast} from 'react-toastify';
import Loader from "../components/Loader";
import Message from "../components/Message";
import { addToCart } from "../slices/cartSlice";


const ProductScreen = () => {

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [qty,setQty] = useState(1); 
  const [rating,setRating]=useState(0);
  const [comment,setComment]=useState(''); 

  const {data:product,isLoading,error,refetch} = useGetProductDetailsQuery(id);
  console.log("Data: ", product);
  console.log('Product ID from URL:', id);

  const [createReview,{isLoading:loadingProductReview}] = useCreateReviewMutation();

  const {userInfo}=useSelector((state)=>state.auth);
  
  const addToCartHandler = ()=>{
    dispatch(addToCart({
      ...product,
      qty
    }));
    navigate('/cart');
  }
  
  const submitHandler =async (e)=>{
    e.preventDefault();
    
    try {
      await createReview({
        id,
        rating,
        comment
      }).unwrap();
      refetch();
      toast.success('comment added');
      setRating(0);
      setComment('')  
    } catch (error) {
      toast.error(error?.data?.message || error.error)
    }
  }


  return (
    <>
      <Link className="btn btn-light my-3" to="/">Go Back</Link>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.data?.message || error.error}</Message>
      ) : (
        <>
        <Row>
        <Col md={5}>
          
          <Image src={product.image} alt={product.name} fluid />
        </Col>
        <Col md={4}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h3>{product.name}</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <Rating value={product.rating} text={`${product.numReviews} reviews`} />
            </ListGroup.Item>
            <ListGroup.Item>
              Price: ${product.price}
            </ListGroup.Item>
            <ListGroup.Item>
              Description: {product.description}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={3}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col>Status:</Col>
                  <Col>
                    <strong>{product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}</strong>
                  </Col>
                </Row>
              </ListGroup.Item>
              {product.countInStock > 0 && (
                <ListGroup.Item>
                  <Row>
                    <Col>Qty</Col>
                    <Col>
                      <Form.Control
                        as='select'
                        value={qty}
                        onChange={(e)=>setQty(Number(e.target.value))}
                      >
                      
                        {[...Array(product.countInStock).keys()].map((x)=>(
                          <option key={x+1} value={x+1}>
                            {x+1}
                          </option>
                        ))}
                        
                      </Form.Control>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <Button 
                  className="btn-block" 
                  type="button" 
                  disabled={product.countInStock===0}
                  onClick={addToCartHandler}
                >
                  Add to Cart
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
        <Row className="review">
            <Col md={6}>
                <h2>Reviews</h2>
                {product.reviews.length===0 && <Message>No Reviews</Message>}
                <ListGroup variant="flush">
                    {product.reviews.map(review=>(
                      <ListGroup.Item key={review._id}>
                        <strong>{review.name}</strong>
                        <Rating value={review.rating} />
                        <p>{review.createdAt.substring(0,10)}</p>
                        <p>{review.comment}</p>
                      </ListGroup.Item>
                    ))}
                    <ListGroup.Item>
                      <h2>Write a Customer Review</h2>
                      {loadingProductReview&&<Loader />}
                      {userInfo?(
                        <Form onSubmit={submitHandler}>
                          <Form.Group controlId="rating" className="my-2">
                            <Form.Label>Rating</Form.Label>
                            <Form.Control as='select' value={rating} onChange={(e)=>setRating(Number(e.target.value))}> 
                              <option value=''>Select...</option>
                              <option value='1'>1-poor</option>
                              <option value='2'>2 - fair</option>
                              <option value='3'>3 - good</option>
                              <option value='4'>4 - very good</option>
                              <option value='5'>5 - excellent</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="comment" className="my-2">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as='textarea' row='3' value={comment} onChange={(e)=>setComment(e.target.value)}>
                            </Form.Control>
                            <Button className="my-2" disabled={loadingProductReview} type="submit" variant="primary">
                              Submit
                            </Button>
                          </Form.Group>
                        </Form>
                      ):(
                        <Message>
                          Please <Link to='login'>Sign in</Link> to write a review{' '}
                        </Message>
                      )}
                    </ListGroup.Item>
                </ListGroup>

            </Col>
        </Row>
      </>
      )}
      
    </>
  )

}

export default ProductScreen